<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:headerTitle>
        <h4 class="card-title">{{ $t('irri_pump_main.maintenance_report') }}</h4>
      </template>
      <template v-slot:body>
        <b-row>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.organization')"
              label-for="org_id"
            >
              <b-form-select
                plain
                id="org_id"
                v-model="search.org_id"
                :options="orgList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.division')"
              label-for="far_division_id"
            >
              <b-form-select
                plain
                id="far_division_id"
                v-model="search.far_division_id"
                :options="divisionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.district')"
              label-for="far_district_id"
            >
              <b-form-select
                plain
                id="far_district_id"
                v-model="search.far_district_id"
                :options="districtList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.upazila')"
              label-for="far_upazilla_id"
            >
              <b-form-select
                plain
                id="far_upazilla_id"
                v-model="search.far_upazilla_id"
                :options="upazilaList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('pump_install.union')"
              label-for="far_union_id"
            >
              <b-form-select
                plain
                id="far_union_id"
                v-model="search.far_union_id"
                :options="unionList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.status')"
              label-for="status"
            >
              <b-form-select
                plain
                id="status"
                v-model="search.status"
                :options="statusList"
                >
                  <template v-slot:first>
                    <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
            </b-form-group>
          </b-col>

          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.from_date')"
              label-for="from_date"
            >
              <flat-pickr class="form-control"
                v-model="search.from_date"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col lg="6" md="6" sm="12" xs="12">
            <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('globalTrans.to_date')"
              label-for="to_date"
            >
              <flat-pickr class="form-control"
                v-model="search.to_date"
                placeholder="Select Date"
              ></flat-pickr>
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
          </b-col>
        </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('irri_pump_main.maintenance_report') }}</h4>
          </template>
          <template v-slot:headerAction>
            <b-button class="btn_add_new mr-2" @click="pdfExport">
              <i class="fas fa-print"></i>{{  $t('globalTrans.print') }}
            </b-button>
            <export-excel
              class="btn btn_add_new"
              :data="dataCustomizeExcel"
              :fields="json_fields"
              :title="$t('irri_pump_main.maintenance_report')"
              :worksheet="$t('irri_pump_main.maintenance_report')"
              name="maintenance-report.xls">
              <i class="far fa-file-excel"></i>{{ $t('globalTrans.export_excel') }}
            </export-excel>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
                <b-row>
                    <b-col>
                        <div class="container">
                            <div class="main-title">
                                <div>
                                    <h5 class="text-center font-weight-bold text-success">{{ $t('irri_pump_main.maintenance_report') }}</h5>
                                </div>
                            </div>
                        </div>
                    </b-col>
                </b-row>
                <b-row class="mt-4">
                    <b-col md="12" class="table-responsive">
                        <b-table thead-class="table_head" bordered hover :items="complains" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                            <template v-slot:cell(index)="data">
                                {{ $n(data.index + 1) }}
                            </template>
                            <template v-slot:cell(complain_id)="data">
                                {{ data.item.complain_id }}
                            </template>
                            <template v-slot:cell(complain_date)="data">
                                {{ data.item.updated_at | dateFormat }}
                            </template>
                            <template v-slot:cell(location)="data">
                                {{ getLocation(data.item) }}
                            </template>
                            <template v-slot:cell(status)="data">
                                {{ getStatus(data.item.status) }}
                            </template>
                            <template v-slot:cell(view_application)="data">
                                <a download target="_blank" :href="irriUrl+data.item.attachment" variant=" iq-bg-success mr-1 mb-1" size="sm" title="Download" @click="download(data.item)"><i class="ri-download-cloud-fill"></i></a>
                            </template>
                            <template v-slot:cell(attachment)="data">
                              <b-button v-if="data.item.tro_equipment_id !== null" v-b-modal.modal-1 variant=" iq-bg-success mr-1 mb-1" size="sm" :title="$t('irri_pump_main.identified_quipment')" @click="view(data.item, $t('irri_pump_main.identified_quipment'))"><i class="ri-eye-line m-1"></i></b-button>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-1" size="lg" :title="modalTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <IdentifiedEuipment :id="id" :complainId="complainId" :complainBy="complainBy" :key="id"/>
      </p>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from '../../../../../config/pluginInit'
import RestApi, { authServiceBaseUrl, irriSchemeServiceBaseUrl } from '@/config/api_config'
import { maintenanceReport, userList } from '../../api/routes'
import IdentifiedEuipment from './IdentifiedEuipment'
import { mapGetters } from 'vuex'
import HierarchycalNames from '@/Utils/common'
import ExportPdf from '@/Utils/export-pdf'
import excel from 'vue-excel-export'
import Vue from 'vue'
Vue.use(excel)

const excelColumn = {
  SL: 'serial',
  'Complain ID': 'complain_id',
  Subject: 'subject',
  Location: 'location',
  Status: 'status_en'
}

const excelColumnBn = {
  'ক্রমিক নং': 'serial',
  'অভিযোগ আইডি': 'complain_id',
  বিষয়: 'subject_bn',
  অবস্থান: 'location',
  অবস্থা: 'status_bn'
}

export default {
  name: 'UiDataTable',
  components: {
    IdentifiedEuipment
  },
  data () {
    return {
      complains: [],
      modalTitle: '',
      baseUrl: irriSchemeServiceBaseUrl,
      search: {
        org_id: 0,
        far_division_id: 0,
        far_district_id: 0,
        far_upazilla_id: 0,
        far_union_id: 0,
        status: 0,
        from_date: '',
        to_date: ''
      },
      statusList: [
          { value: 2, text: 'Closed' },
          { value: 7, text: 'Complete' },
          { value: 100, text: 'Processing' }
      ],
      id: 0,
      rows: [],
      districtList: [],
      upazilaList: [],
      unionList: [],
      userList: [],
      status: '',
      complainId: '',
      complainBy: ''
    }
  },
  computed: {
    ...mapGetters({
      authUser: 'Auth/authUser'
    }),
    json_fields: function () {
      return this.$i18n.locale === 'bn' ? excelColumnBn : excelColumn
    },
    dataCustomizeExcel () {
        const listData = this.complains
        var serial = 0
        const listContractor = listData.map(item => {
          const allNames = HierarchycalNames.getCommonHierarchyNames(item.far_union_id, 'union')
          serial += 1
          return Object.assign({}, item, {
            serial: this.$n(serial),
            complain_id: this.$n(item.complain_id, { useGrouping: false }),
            location: `${allNames.divisionName}, ${allNames.districtName}, ${allNames.upazilaName}, ${allNames.unionName}`
          })
        })
        return listContractor
    },
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload) {
        return true
      } else if (this.loading) {
        return true
      } else {
        return false
      }
    },
    columns () {
        const labels = [
            { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
            { label: this.$t('irri_pump_main.complain_id'), class: 'text-left' },
            { label: this.$t('irri_pump_main.subject'), class: 'text-left' },
            { label: this.$t('pump_install.location'), class: 'text-left' },
            { label: this.$t('globalTrans.status'), class: 'text-left' },
            { label: this.$t('irri_pump_main.attachment'), class: 'text-left' }
        ]

        let keys = []
        keys = [
            { key: 'index' },
            { key: 'complain_id' },
            { key: 'subject' },
            { key: 'location' },
            { key: 'status' },
            { key: 'attachment' }
        ]
        return labels.map((item, index) => {
            return Object.assign(item, keys[index])
        })
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    divisionList: function () {
      const divisions = this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
      return divisions.map((item) => {
          if (this.$i18n.locale === 'bn') {
              return { value: item.value, text: item.text_bn !== 'undefined' ? item.text_bn : '' }
          } else {
              return { value: item.value, text: item.text_en !== 'undefined' ? item.text_en : '' }
          }
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    },
    'search.far_division_id': function (newVal, oldVal) {
      this.districtList = this.getDistrictList(newVal)
    },
    'search.far_district_id': function (newVal, oldVal) {
      this.upazilaList = this.getUpazilaList(newVal)
    },
    'search.far_upazilla_id': function (newVal, oldVal) {
      this.unionList = this.getUnionList(newVal)
    }
  },
  created () {
    this.getUserList()
  },
  mounted () {
    core.index()
    if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.org_id
      })
    } else if (this.authUser.role_id === 0) {
      this.search = Object.assign({}, this.search, {
        org_id: this.authUser.office_detail.org_id,
        far_division_id: this.authUser.office_detail.division_id,
        far_district_id: this.authUser.office_detail.district_id,
        far_upazilla_id: this.authUser.office_detail.upazilla_id
      })
    }
  },
  methods: {
    searchData () {
      if (this.authUser.role_id === 0 && this.authUser.is_org_admin === 1) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.org_id
        })
      } else if (this.authUser.role_id === 0) {
        this.search = Object.assign({}, this.search, {
          org_id: this.authUser.office_detail.org_id,
          far_division_id: this.authUser.office_detail.division_id,
          far_district_id: this.authUser.office_detail.district_id,
          far_upazilla_id: this.authUser.office_detail.upazilla_id
        })
      }
      this.loadData()
    },
    async loadData () {
      this.$store.dispatch('mutateCommonProperties', { loading: true })
      const params = Object.assign({}, this.search)
      await RestApi.getData(irriSchemeServiceBaseUrl, maintenanceReport, params).then(response => {
        if (response.success) {
          this.complains = this.getRelationalData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getRelationalData (data) {
      const statusList = this.$store.state.IrriPumpMaintenance.statusList
      const listData = data.map(item => {
        const statusObj = statusList.find(status => status.value === item.status)
        const statusData = { status_en: statusObj.text_en, status_bn: statusObj.text_bn }
        return Object.assign({}, item, statusData)
      })
      return listData
    },
    view (item, modalTitle) {
      this.id = item.id
      this.modalTitle = modalTitle
      this.complainBy = (this.$i18n.locale === 'bn') ? item.name_bn : item.name
    },
    getUserList () {
      RestApi.getData(authServiceBaseUrl, userList).then(response => {
        if (response.success) {
          this.userList = response.data
        }
      })
    },
    getDistrictList (divisionId = null) {
      const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
      if (divisionId) {
        return districtList.filter(district => district.division_id === divisionId)
      }
      return districtList
    },
    getUpazilaList (districtId = null) {
      const upazilaList = this.$store.state.commonObj.upazilaList.filter(item => item.status === 0)
      if (districtId) {
        return upazilaList.filter(upazila => upazila.district_id === districtId)
      }
      return upazilaList
    },
    getUnionList (upazilaId = null) {
      const unionList = this.$store.state.commonObj.unionList.filter(item => item.status === 0)
      if (upazilaId) {
        return unionList.filter(union => union.upazilla_id === upazilaId)
      }
      return unionList
    },
    getLocation (item) {
      const location = []
      const divisionList = this.$store.state.commonObj.divisionList
      const tmpDivision = divisionList.find(division => division.value === item.far_division_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDivision.text_bn)
      } else {
        location.push(tmpDivision.text_en)
      }
      const districtList = this.$store.state.commonObj.districtList
      const tmpDistrict = districtList.find(district => district.value === item.far_district_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpDistrict.text_bn)
      } else {
        location.push(tmpDistrict.text_en)
      }
      const upazilaList = this.$store.state.commonObj.upazilaList
      const tmpUpazila = upazilaList.find(upazila => upazila.value === item.far_upazilla_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUpazila.text_bn)
      } else {
        location.push(tmpUpazila.text_en)
      }
      const unionList = this.$store.state.commonObj.unionList
      const tmpUnion = unionList.find(union => union.value === item.far_union_id)
      if (this.$i18n.locale === 'bn') {
        location.push(tmpUnion.text_bn)
      } else {
        location.push(tmpUnion.text_en)
      }
      return location.join(', ')
    },
    getStatus (status) {
        if (status === 1) {
            return 'Pending'
        } else if (status === 2) {
            return 'Closed'
        } else if (status === 3) {
            return 'Reviewed'
        } else if (status === 4) {
            return 'Required Maintenance'
        } else if (status === 5) {
            return 'Maintenance Task'
        } else if (status === 6) {
            return 'Requisition'
        } else if (status === 7) {
            return 'Complete'
        }
    },
    pdfExport () {
      const rowData = this.getPdfData()
      const labels = [
          { text: this.$t('globalTrans.sl_no'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.complain_id'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('irri_pump_main.subject'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('pump_install.location'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) },
          { text: this.$t('globalTrans.status'), fontSize: (this.$i18n.locale === 'bn' ? 14 : 12) }
        ]
      rowData.unshift(labels)
      const reportTitle = this.$t('irri_pump_main.maintenance_report')
      const columnWids = ['7%', '10%', '*', '*', '20%']
      ExportPdf.exportPdf(irriSchemeServiceBaseUrl, '/report-heading/detail', this.search.org_id, reportTitle, rowData, columnWids)
    },
    getPdfData () {
      let keys = []
      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'serial_no' },
          { key: 'complain_id' },
          { key: 'subject_bn' },
          { key: 'location' },
          { key: 'status_bn' }
        ]
      } else {
        keys = [
          { key: 'serial_no' },
          { key: 'complain_id' },
          { key: 'subject' },
          { key: 'location' },
          { key: 'status_en' }
        ]
      }
      var serial = 0
      return this.complains.map(item => {
        const allNames = HierarchycalNames.getCommonHierarchyNames(item.far_union_id, 'union')
          serial += 1
          const rowData = keys.map(keyItem => {
            if (keyItem.key === 'serial_no') {
                return { text: this.$n(serial) }
            }
            if (keyItem.key === 'location') {
                return { text: `${allNames.divisionName}, ${allNames.districtName}, ${allNames.upazilaName}, ${allNames.unionName}` }
            }
            if (keyItem.key === 'complain_id') {
              return { text: this.$n(item.complain_id, { useGrouping: false }) }
            }
            return { text: item[keyItem.key] }
        })

        return rowData
      })
    }
  }
}
</script>
