<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <b-row>
                    <b-col lg="3" sm="12">
                      <p class="text-dark">{{ $t('irri_pump_main.complain_id') }} :</p>
                    </b-col>
                    <b-col lg="9" sm="12">
                        <p class="text-dark">{{ pumpComplainId }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col lg="3" sm="12">
                      <p class="text-dark">{{ $t('irri_pump_main.complained_by') }} :</p>
                    </b-col>
                    <b-col lg="9" sm="12">
                        <p class="text-dark">{{ pumpComplainBy }}</p>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-row>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('irri_pump_main.division') }} : {{ getDivision(complainEquipment.division_id) }}</p>
                            </b-col>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('irri_pump_main.district') }} : {{ getDistrict(complainEquipment.district_id) }}</p>
                            </b-col>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('org_pro_upazilla.upazilla') }} : {{ getUpazilla(complainEquipment.upazilla_id) }}</p>
                            </b-col>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('irri_pump_main.union') }} : {{ getUnion(complainEquipment.union_id) }}</p>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('irri_pump_main.mauza_no') }} : {{ complainEquipment.mauza_no }}</p>
                            </b-col>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('irri_pump_main.jl_no') }} : {{ complainEquipment.jl_no }}</p>
                            </b-col>
                            <b-col md="3">
                                <p class="text-dark">{{ $t('irri_pump_main.plot_no') }} : {{ complainEquipment.plot_no }}</p>
                            </b-col>
                        </b-row>
                        <p style="font-weight:bold; color:gray; text-transform:capitalize">{{ $t('irri_pump_main.trouble_equipment_details') }}</p>
                        <hr style="margin-top:-15px">
                        <b-row>
                            <table class="table table-bordered" style="width: 100%;margin: 0px 12px;margin-bottom: 20px;">
                                <tr>
                                    <th>{{ $t('irri_pump_main.name') }}</th>
                                    <th>{{ $t('irri_pump_main.note') }}</th>
                                </tr>
                                <tr v-for="(detail,index) in complainEquipmentDetails" :key="index">
                                    <td>{{ ($i18n.locale === 'bn') ? detail.name_bn : detail.name }}</td>
                                    <td>{{ ($i18n.locale === 'bn') ? detail.note_bn : detail.note }}</td>
                                </tr>
                            </table>
                        </b-row>
                        <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                        </div>
                    </b-col>
                </b-row>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { irriSchemeServiceBaseUrl } from '../../../../../config/api_config'
import { pumpComplTroEquipDetail } from '../../api/routes'

export default {
    name: 'FormLayout',
    props: ['id', 'farmer_id', 'complainId', 'complainBy'],
    mounted () {
        core.index()
    },
    data () {
        return {
            complainEquipment: '',
            complainEquipmentDetails: '',
            far_complain_id: '',
            pumpComplainId: '',
            pumpComplainBy: ''
        }
    },
    created () {
        this.far_complain_id = this.$props.id
        this.pumpComplainId = this.$props.complainId
        this.pumpComplainBy = this.$props.complainBy
        this.getTroubleEquipmentDetails()
    },
    methods: {
        getTroubleEquipmentDetails () {
            RestApi.getData(irriSchemeServiceBaseUrl, pumpComplTroEquipDetail + '/' + this.far_complain_id).then(response => {
                if (response.success) {
                    this.complainEquipment = response.data.troubleEquipment
                    this.complainEquipmentDetails = response.data.troubleEquipmentDetails
                }
            })
        },
        getDivision (divisionId) {
            if (divisionId === null || divisionId === undefined) {
                return ''
            }
            const divisionList = this.$store.state.commonObj.divisionList
            const tmpDivision = divisionList.find(division => division.value === divisionId)
            return (this.$i18n.locale === 'bn') ? tmpDivision.text_bn : tmpDivision.text_en
        },
        getDistrict (districtId) {
            if (districtId === null || districtId === undefined) {
                return ''
            }
            const districtList = this.$store.state.commonObj.districtList
            const tmpDistrict = districtList.find(district => district.value === districtId)
            return (this.$i18n.locale === 'bn') ? tmpDistrict.text_bn : tmpDistrict.text_en
        },
        getUpazilla (upazillaId) {
            if (upazillaId === null || upazillaId === undefined) {
                return ''
            }
            const upazillaList = this.$store.state.commonObj.upazilaList
            const tmpUpazilla = upazillaList.find(upazilla => upazilla.value === upazillaId)
            return (this.$i18n.locale === 'bn') ? tmpUpazilla.text_bn : tmpUpazilla.text_en
        },
        getUnion (unionId) {
            if (unionId === null || unionId === undefined) {
                return ''
            }
            const unionList = this.$store.state.commonObj.unionList
            const tmpUnion = unionList.find(union => union.value === unionId)
            return (this.$i18n.locale === 'bn') ? tmpUnion.text_bn : tmpUnion.text_en
        }
    }
}
</script>
